<template>
  <div class="container">
    <div class="container_content" v-if="!isEmpty">
      <div class="container_content_item" v-for="(item, i) in list" :key="i" @click="toActivityDetail(item.id)">
        <img :src="item?.picUrl" alt="">
        <div class="container_content_item_content">
          <div class="container_content_item_content_title">{{ item?.name }}</div>
          <div class="container_content_item_content_center">

            <div class="container_content_item_content_center_status">长期活动</div>
            <div class="container_content_item_content_center_score">可{{ item?.scoreType=='1'?'获得':'消耗' }}{{ item?.score }}积分</div>
          </div>
          <div class="container_content_item_content_bottom">
            <div class="container_content_item_content_bottom_num">
              <div style="margin-top: 2px">
                {{item?.starttime}}
              </div>
              <div style="margin-top: 2px">
                至
              </div>
              <div style="margin-top: 2px">
                {{item?.endtime}}
              </div>
            </div>
            <div class="container_content_item_content_bottom_address">{{ item?.addressDetail?.length>10?(item?.addressDetail?.slice(0,10)+'...'):item?.addressDetail }}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="container_empty" v-if="isEmpty">
      <el-empty description="暂无数据"/>
    </div>
  </div>
  <div class="container_page">
    <pagination
        v-show="total > 0"
        :total="total"
        layout="total,  prev, pager, next, jumper"
        v-model:page="query.pageNum"
        v-model:limit="query.pageSize"
        @pagination="activityList()"
    />
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router"
import {getActivityList, getSignupList} from "@/api/activity";
import {ref} from "vue";
import Pagination from '@/components/Pagination'
const router = useRouter();

const isEmpty = ref(false)
//分页
const query = ref({})
query.value = {pageNum: 1, pageSize: 8}

const total = ref(1);
const list = ref([]);

const route = useRoute();
const active = route.query.active;

const longActivityList = async () => {
  let response = await getActivityList(query.value, {longType: "1", isMain: "1",checkStatus:"1"});
  console.log(response.rows)
    list.value = response.rows
    total.value = response.total
  if(query.value.pageNum==1){
    if(response.rows.length==0){
      isEmpty.value=true
    }
  }
}
const signupList = async () => {
  let response = await getSignupList(query.value, {});
    for (const e of response.rows) {
      list.value.push(e.activity)
    }
    total.value = response.total
  if(query.value.pageNum==1){
    if(response.rows.length==0){
      isEmpty.value=true
    }
  }
}
const activityList = async () => {
  if (active == 'longActivity') {
    await longActivityList()
  } else {
    await signupList();
  }
}
activityList();

const toActivityDetail = (id) => {
  router.push({
    path: '/activityDetail',
    query: {
      id,
      type: "claim",
      active:active
    },
  })
}


</script>

<style lang="scss" scoped>
.container_page {
  background-color: #F5F5F5;
  height: 60px;
  text-align: center;
  display: inline-block;
}
.container {
  background-color: #F5F5F5;
  padding: 60px;

  &_empty {
    padding: 0 30px 30px 30px;
    height: 930px;
  }

  &_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 930px;
    position: relative;

    &_item {
      margin:20px;
      width: calc(25% - 40px);
      height: 410px;
      border-radius: 6px 6px 6px 6px;
      background-color: #FFFFFF;

      img {
        width: 100%;
        height: 200px;
      }

      &_content {

        padding: 20px 20px 38px 20px;
        border-radius: 0 0 6px 6px;
        display: flex;
        flex-direction: column;

        &_title {
          top: 20px;
          height: 60px;
          line-height: 30px;
          color: rgba(33, 33, 33, 100);
          font-size: 20px;
          text-align: left;
          font-family: HarmonyOS_Sans_SC-medium;
        }

        &_center {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &_status {
            line-height: 14px;
            border-radius: 2px;
            background-color: rgba(33, 33, 33, 100);
            color: rgba(255, 255, 255, 100);
            font-size: 14px;
            font-weight: bolder;
            padding: 6px;
          }

          &_score {
            line-height: 26px;
            color: rgba(33, 33, 33, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-medium;
            font-weight: bolder;
          }
        }

        &_bottom {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &_num {
            line-height: 16px;
            color: rgba(255, 145, 0, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-semiBold;
            display: flex;
            flex-direction: column;
          }

          &_address {
            line-height: 16px;
            color: rgba(189, 189, 189, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }
      }
    }

    &_next {

      width: 395px;
      height: 410px;
      border-radius: 6px 6px 6px 6px;
      background-color: #FFFFFF;
      position: absolute;
      left: 1305px;
      top: 464px;

      &_content {
        margin-top: 72px;
        height: 266px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .el-button {
          width: 320px;
          height: 80px;
        }

        &_page {
          line-height: 20px;
          color: rgba(140, 162, 170, 100);
          font-size: 18px;
          text-align: center;
          font-family: HarmonyOS_Sans_SC-regular;
          margin-top: 43px;
          margin-bottom: 43px;
        }
      }
    }
  }

}
</style>
